.App {
  width: 800px;
  margin: 0 auto;
}

form {
  width: 800px;
  margin: 0 auto;
}

input[type=text]{
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 1px solid #ccc;
  box-sizing: border-box;
}

button {
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}
